<template>
  <router-view :key="fullPath"/>
</template>
<script setup>
import { useRoute } from 'vue-router';
import { ref, watch } from 'vue';

const route = useRoute();
const fullPath = ref('');

watch(route,(newval) => {
  fullPath.value = newval.fullPath;
});

</script>
<style lang="scss">
@import '~@/assets/css/reset.scss';
</style>

