<template>
  <div class="ymxk-business-container">
    <div class="bg-left"></div>
    <div class="bg-right"></div>
    <div class="ymxk-business-box">
      <div class="ymxk-business-content">
        <div class="ymxk-business-title">
          <span class="ymxk-business-title-left"></span>
          <span class="ymxk-business-title-middle">企业荣誉</span>
          <span class="ymxk-business-title-right"></span>
        </div>
        <div class="ymxk-business-img">
          <div
            class="ymxk-business-img-list"
            v-for="(item, index) in businessList"
            :key="index"
          >
            <div class="bussion-sc">
              <img :src="item.img" alt="" />
            </div>
            <p class="ymxk-business-text">{{ item.name }}</p>
          </div>
        </div>
        <div class="yxmk-business-more" v-if="!honner">
          <span class="yxmk-business-more-click" @click="awards">更多奖项</span>
          <img src="~@/assets/img/ymxk-more.png" alt="" @click="awards" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { defineProps,onMounted } from 'vue';
const { anchor } = defineProps({
  anchor: Boolean
});
//企业荣誉
const businessList = [
  {
    name: '荣获天津经济技术开发区与中国服务贸易协会商业保理专委会颁发的天津商业保理创新发展基地裕业奖',
    img: require('@/assets/img/tjsy.png')
  },
  {
    name: '荣获第六届CMO 金匠奖“年度内容营销大奖”',
    img: require('@/assets/img/xxdj.png')
  },
  {
    name: '北京国美融通科技有限公司顺利通过科技部火炬中心2022年“高新技术企业”认定',
    img: require('@/assets/img/gxkj.png')
  },
  {
    name: '荣获新华社瞭望智库、财经国家周刊颁发的“十三五”数字普惠金融示范案例”',
    img: require('@/assets/img/xfjr.png')
  },
  {
    name: '荣登天津经济技术开发区“2020年度百强企业”榜单',
    img: require('@/assets/img/lyjc.png')
  },
  {
    name: '荣获中国经营报“2021卓越竞争力消费金融创新机构大奖”',
    img: require('@/assets/img/zyjj.png')
  }
];
//更多奖项
const router = useRouter();

const awards = () => {
  router.push({
    path: '/about'
  });
};
const honner=localStorage.getItem('honner');

onMounted(() => {
  if (anchor) {
    window.scrollTo({
      top: document.querySelector('.ymxk-business-container').getBoundingClientRect().top
    });
  }
});
</script>
<style lang="scss" scoped>
.ymxk-business-container {
  background:linear-gradient(
      136deg,
      rgba(131, 203, 255, 0.03) 0%,
      rgba(35, 163, 255, 0.03) 100%
    );
  position: relative;
  .bg-left {
    background-image: url('../assets/img/bg/honer-left.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 856px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .bg-right {
    background-image: url('../assets/img/bg/honer-right.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 856px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ymxk-business-box {
    position: relative;
    width: 100%;
    height: 100%;
    .ymxk-business-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 60px 0 50px;
      .ymxk-business-title {
        margin-bottom: 49px;
        .ymxk-business-title-left {
          width: 8px;
          height: 8px;
          background: #23a1ff;
          opacity: 0.3;
          display: inline-block;
          vertical-align: top;
        }
        .ymxk-business-title-middle {
          width: 120px;
          height: 30px;
          font-size: 30px;
          font-weight: bold !important;
          color: #000000;
          line-height: 30px;
          margin: 0 10px;
        }
        .ymxk-business-title-right {
          width: 8px;
          height: 8px;
          background: #23a1ff;
          opacity: 0.3;
          display: inline-block;
        }
      }
      .ymxk-business-img {
        .ymxk-business-img-list {
          width: 370px;
          display: inline-table;
          margin-right: 45px;
          margin-bottom: 40px;
          font-size: 0; //解决img标签与p标签之间的默认间隔
          .bussion-sc {
            width: 370px;
            height: 214px;
            overflow: hidden;
            img {
            width: 100%;
            height: 100%;
            transition: all 0.5s;
            }
            img:hover {
              transform: scale(1.1);
            }
          }

          .ymxk-business-text {
            background: #f5f8ff;
            height: 66px;
            font-size: 14px;
            color: #000000;
            line-height: 24px;
            padding: 9px 21px;
          }
        }
        .ymxk-business-img-list:nth-of-type(3n) {
          margin-right: 0;
        }
      }
      .yxmk-business-more {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 10px;
        .yxmk-business-more-click {
          font-size: 16px;
          color: #23a0ff;
          line-height: 16px;
          margin-right: 5px;
          cursor: pointer;
        }
        img {
          width: 8px;
          height: 8px;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
