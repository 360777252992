<template>
  <default-layout class="home-view-container" :navId="1">
    <!-- banner 图 start -->
    <el-carousel class="ymxk-banner-container" height="100%" arrow="never" :pause-on-hover="false">
      <el-carousel-item v-for="item in banner.bannerList" :key="item.id">
        <a :href="item.url" target="_blank" rel="noopener noreferrer">
          <div class="ymxk-banner-img" :style="{
            background: `url(${item.imageUrl}) center center no-repeat`,
            backgroundSize: 'cover',
          }"></div>
        </a>
      </el-carousel-item>
    </el-carousel>
    <!-- banner 图 end -->
    <!-- 企业简介 start -->
    <Introduce></Introduce>
    <!-- 企业简介 end -->
    <!-- 产品与服务 start -->
    <Product></Product>
    <!-- 产品与服务 end -->
    <!-- 企业社会责任 start -->
    <Responsibility></Responsibility>
    <!-- 企业社会责任 end -->
    <!-- 新闻动态 start -->
    <News></News>
    <!-- 新闻动态 end -->
    <!-- 企业荣誉 start -->
    <Business></Business>
    <!-- 企业荣誉 end -->
    <!-- 合作伙伴 start -->
    <!-- <Partner></Partner> -->
    <!-- 合作伙伴 end -->
    <back-top></back-top>
    <!-- 老域名弹窗跳转新域名 -->
    <Teleport to="body">
      <Dialog></Dialog>
    </Teleport>
  </default-layout>
</template>

<script setup>
import DefaultLayout from '@/layout/default.vue';
import Introduce from '@/components/introduce.vue';
import Product from '@/components/product.vue';
import Responsibility from '@/components/responsibility.vue';
import News from '@/components/news.vue';
import Business from '@/components/business.vue';
// import Partner from '@/components/partner.vue';
import BackTop from './components/backTop.vue';
import Dialog from '@/components/dialog.vue';
import { reactive, onMounted } from 'vue';
import { banners } from '../../assets/js/http';
// const bannerList = [
//   {
//     id: 1,
//     imageUrl: 'https://via.placeholder.com/1920x800.png/ff0000',
//     url: 'https://www.baidu.com'
//   },
//   {
//     id: 2,
//     imageUrl: 'https://via.placeholder.com/1920x800.png/ff00ff',
//     url: 'https://www.baidu.com'
//   },
//   {
//     id: 3,
//     imageUrl: 'https://via.placeholder.com/1920x800.png/000000',
//     url: 'https://www.baidu.com'
//   }
// ];
const banner = reactive({
  bannerList: []
});

onMounted(() => {
  banners({ plat: 'pc' }).then((res) => {
    if (res && res.data.code === 200) {
      banner.bannerList = res.data.data.banner;
    } else {
      console.log(res.data.msg);
    }
  });
});
</script>

<style lang="scss" scoped>
.ymxk-banner-container {
  width: 100vw;
  height: 100vh;
  .ymxk-banner-img {
    width: 100%;
    height: 100%;
    display: block;
    margin: 0 auto;
  }
  /deep/ .el-carousel__indicators--horizontal {
    bottom: 18px !important;
  }
}
</style>
