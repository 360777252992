<template>
  <div class="ymxk-introduce-container">
    <div class="bg-left"></div>
    <div class="bg-right"></div>
    <div class="ymxk-introduce-box">
      <div class="ymxk-introduce-title">
        <span class="ymxk-introduce-title-left"></span>
        <span class="ymxk-introduce-title-middle">企业简介</span>
        <span class="ymxk-introduce-title-right"></span>
      </div>
      <div class="ymxk-introduce-describe">
        <p>
          盈美信科作为一家以“数字金融科技”为战略发展核心的金融科技企业，秉持独立发展、合规运营的发展原则，坚持服务实体经济的发展理念，致力于为个人及中小微机构、产业实体、金融合作伙伴提供多元化、全方位数字金融科技解决方案。
        </p>
        <p>
          盈美信科重点布局数字场景科技、数字产融科技以及数字支付科技等三个发展方向，依托多年以来在人工智能、数字决策、智能风控、云计算等方面积累的丰厚实践经验及金融科技能力，继续为更多元化客户提供更为丰富的金融科技服务产品，助力产业数字化转型发展。
        </p>
      </div>
      <ul class="ymxk-introduce-data-box">
        <li>
          <div class="number-box">
            <numberScroll figure="1000" :start="start"></numberScroll>
            <div><span>亿+</span></div>
          </div>
          <p class="name-box">普惠信贷规模</p>
        </li>
        <li class="gray-line"></li>
        <li>
          <div class="number-box">
            <numberScroll figure="4500" :start="start"></numberScroll>
            <div><span>万+</span></div>
          </div>
          <p class="name-box">注册用户数</p>
        </li>
        <li class="gray-line"></li>
        <li>
          <div class="number-box">
            <numberScroll figure="240" :start="start"></numberScroll>
            <div><span>家+</span></div>
          </div>
          <p class="name-box">合作品牌</p>
        </li>
        <li class="gray-line"></li>
        <li>
          <div class="number-box">
            <numberScroll figure="100" :start="start"></numberScroll>
            <div><span>万+</span></div>
          </div>
          <p class="name-box">扶持小微用户数</p>
        </li>
      </ul>
      <p class="ymxk-introduce-data-footer">数据截止于2022年底</p>
    </div>
  </div>
</template>

<script setup>
import numberScroll from './numberScroll';
import { onMounted, ref,onUnmounted,defineProps } from 'vue';
import { isInViewPortOfOne } from '@/assets/js/viewPort';
const { anchor } = defineProps({
  anchor: Boolean
});
const start = ref(false);

let timer = null;

function onScroll() {
  if (!start.value && isInViewPortOfOne('.ymxk-introduce-data-box')) {
    timer = setTimeout(() => {
      start.value = true;
    },0);
  }
}

onMounted(() => {
  if (anchor) {
    window.scrollTo({
      top: document.querySelector('.ymxk-introduce-container').getBoundingClientRect().top
    });
  }
  onScroll();
  document.addEventListener('scroll', onScroll, false);
});
onUnmounted(() => {
  document.removeEventListener('scroll', onScroll);
  clearTimeout(timer);
});



</script>
<style lang="scss" scoped>
.ymxk-introduce-container {
  padding: 60px 0 28px;
  background: linear-gradient(136deg, #fcfeff 0%, #f9fdff 100%);
  position: relative;
  .bg-left {
    background-image: url('../assets/img/bg/qiye-left.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 515px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 0;
  }
  .bg-right {
    background-image: url('../assets/img/bg/qiye-right.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 515px;
    position: absolute;
    right: 0;
    bottom: 0;
  }
  .ymxk-introduce-box {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .ymxk-introduce-title {
      margin-bottom: 20px;
      text-align: center;
      .ymxk-introduce-title-left {
        width: 8px;
        height: 8px;
        background: #23a1ff;
        opacity: 0.3;
        display: inline-block;
        vertical-align: top;
      }
      .ymxk-introduce-title-middle {
        width: 120px;
        height: 30px;
        font-size: 30px;
        font-weight: bold !important;
        color: #000000;
        line-height: 30px;
        margin: 0 10px;
      }
      .ymxk-introduce-title-right {
        width: 8px;
        height: 8px;
        background: #23a1ff;
        opacity: 0.3;
        display: inline-block;
      }
    }
    .ymxk-introduce-describe {
      padding: 0 50px;
      font-size: 14px;
      font-weight: normal;
      color:#4C4D4E ;
      line-height: 28px;
      text-align: center;
    }
    .ymxk-introduce-data-box {
      width: 100%;
      box-sizing: border-box;
      padding: 0 95px 0 67px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 78px 0 60px;
      li {
        text-align: center;
        .number-box {
          font-size: 42px;
          font-weight: normal;
          color: #23a0ff;
          line-height: 49px;
          margin-bottom: 7px;
          display: flex;
          align-items: flex-end;
          span {
            font-size: 30px;
            font-weight: normal;
            color: #23a0ff;
            line-height: 35px;
            margin-left: 4px;
          }
        }
        .name-box {
          font-size: 14px;
          font-weight: normal;
          color: #000000;
          line-height: 28px;
        }
      }
      .gray-line {
        width: 2px;
        height: 90px;
        background: #e8eeff;
      }
    }
    .ymxk-introduce-data-footer {
      font-size: 14px;
      font-weight: normal;
      color: #AFAFAF;
      line-height: 28px;
      text-align: center;
    }
  }
}
</style>
