<template>
  <div class="ymxk-responsibility-container">
    <div class="bg-left"></div>
    <div class="bg-right"></div>
    <div class="ymxk-responsibility-box">
      <div class="ymxk-responsibility-content">
        <div class="ymxk-responsibility-title">
          <span class="ymxk-responsibility-title-left"></span>
          <span class="ymxk-responsibility-title-middle">企业社会责任</span>
          <span class="ymxk-responsibility-title-right"></span>
        </div>
        <p class="ymxk-responsibility-introduce">
          盈美信科积极履行企业社会责任，保护金融消费者合法权益，将消费者权益保护纳入公司治理、企业文化建设和经营发展战略。同时，践行国家为中小微企业及广大消费者解忧、纾困等相关政策指引，为有需要的群体提供更加便捷、高效的金融科技服务。
        </p>
        <div class="ymxk-responsibility-img">
          <div class="ymxk-responsibility-img-list">
            <div class="ymxk-responsibility-img-left-top">
              <div class="ymxk-responsibility-img-left">
                <img
                  src="../assets/img/index-customer.png"
                  alt=""
                  class="ymxk-responsibility-image"
                />
              </div>
              <div class="ymxk-responsibility-img-top">
                <img
                  src="~@/assets/img/ymxk-responsibility-left.png"
                  alt=""
                  class="ymxk-responsibility-image"
                />
              </div>
            </div>
            <div class="ymxk-responsibility-img-right-top">
              <div class="ymxk-responsibility-right-content">
                <p class="ymxk-responsibility-right-consumer">消费者权益保护</p>
                <p class="ymxk-responsibility-right-detail">
                  以保护金融消费者合法权益为目的，将消费者权益保护纳入公司治理、企业文化建设和经营发展战略，积极开展金融知识科普、反诈骗、遏制“反催收”黑产等各类活动，践行社会主体责任，充分发挥金融服务实体经济的职能。
                </p>
              </div>
              <div class="ymxk-responsibility-userinfo-detail">
                <div class="ymxk-responsibility-userinfo">
                  <p>·金融消费者个人信息保护</p>
                  <p>·警企联动，保护消费者权益</p>
                  <p>·消费者保护教育</p>
                </div>
                <div
                  class="ymxk-responsibility-img-right-more"
                  @click="learnConsumer"
                >
                  <img src="~@/assets/img/ymxk-learn-more.png" alt="" />
                  <p class="ymxk-responsibility-img-learn-more">了解更多</p>
                </div>
              </div>
            </div>
          </div>
          <div class="ymxk-responsibility-img-list">
            <div class="ymxk-responsibility-img-left-bottom">
              <div class="ymxk-responsibility-right-content">
                <p class="ymxk-responsibility-right-consumer">助力小微</p>
                <p class="ymxk-responsibility-right-detail">
                  践行扶助小微、三农社会责任，以数字技术打破错配桎梏，精准化找到有需求的人群，定向输血赋能。并通过对特定地区，特定收入群体及小微企业主，减免息费、延长信贷周期、提升授信额度等方式，支持实体经济发展，缓解企业资金压力。
                </p>
              </div>
              <div class="ymxk-responsibility-userinfo-detail">
                <div class="ymxk-responsibility-userinfo">
                  <p>·扶微助农，定向赋能</p>
                  <p>·易同守护，免息助力</p>
                </div>
                <div
                  class="ymxk-responsibility-img-right-more"
                  @click="learnMore"
                >
                  <img src="~@/assets/img/ymxk-learn-more.png" alt="" />
                  <p class="ymxk-responsibility-img-learn-more">了解更多</p>
                </div>
              </div>
            </div>
            <div class="ymxk-responsibility-img-left-top">
              <div class="ymxk-responsibility-img-left">
                <img
                  src="../assets/img/index-zlxw.png"
                  alt=""
                  class="ymxk-responsibility-image"
                />
              </div>
              <div class="ymxk-responsibility-img-top">
                <img
                  src="~@/assets/img/ymxk-responsibility-left.png"
                  alt=""
                  class="ymxk-responsibility-image"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
const router = useRouter();
//助力小微
const learnMore = () => {
  router.push({
    path: '/assistance'
  });
};
//消费者保护
const learnConsumer = () => {
  router.push({
    path: '/consumer'
  });
};

</script>
<style lang="scss" scoped>
.ymxk-responsibility-container {
  background: linear-gradient(
    136deg,
    rgba(131, 203, 255, 0.03) 0%,
    rgba(35, 163, 255, 0.03) 100%
  );

  position: relative;
  .bg-left {
    background-image: url("../assets/img/bg/zeren-left.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 841px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .bg-right {
    background-image: url("../assets/img/bg/zeren-right.png");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 841px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ymxk-responsibility-box {
    position: relative;
    width: 100%;
    height: 100%;
    .ymxk-responsibility-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 61px 0 60px;
      .ymxk-responsibility-title {
        .ymxk-responsibility-title-left {
          width: 8px;
          height: 8px;
          background: #23a1ff;
          opacity: 0.3;
          display: inline-block;
          vertical-align: top;
        }
        .ymxk-responsibility-title-middle {
          width: 120px;
          height: 30px;
          font-size: 30px;
          font-weight: bold !important;
          color: #000000;
          line-height: 30px;
          margin: 0 10px;
        }
        .ymxk-responsibility-title-right {
          width: 8px;
          height: 8px;
          background: #23a1ff;
          opacity: 0.3;
          display: inline-block;
        }
      }
      .ymxk-responsibility-introduce {
        width: 100%;
        box-sizing: border-box;
        padding: 0 50px;
        font-size: 14px;
        font-weight: normal;
        color: #4c4d4e;
        line-height: 28px;
        text-align: center;
        margin-top: 17px;
        margin-bottom: 44px;
      }
      .ymxk-responsibility-img {
        .ymxk-responsibility-img-list {
          display: flex;
          overflow: hidden;
          .ymxk-responsibility-img-left-top {
            position: relative;
            cursor: pointer;
            transition: all 0.5s;
            .ymxk-responsibility-img-left {
              width: 532px;
              height: 300px;

              .ymxk-responsibility-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.5s;
              }
            }

            .ymxk-responsibility-img-top {
              width: 532px;
              height: 300px;
              position: absolute;
              z-index: 10;
              top: 0;
              .ymxk-responsibility-image {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.5s;
              }
            }
          }
          .ymxk-responsibility-img-left-top:hover {
            transform: scale(1.1);
          }

          .ymxk-responsibility-img-right-top {
            flex: 1;
            background: url("~@/assets/img/ymxk-responsibility-right.png")
              no-repeat;
            background-size: 100% 100%;
            margin-left: -46px;
            z-index: 11;
            text-align: left;
            .ymxk-responsibility-right-content {
              margin: 40px 31px 16px 54px;

              .ymxk-responsibility-right-consumer {
                font-size: 24px;
                color: #ffffff;
                line-height: 28px;
              }
              .ymxk-responsibility-right-detail {
                font-size: 16px;
                font-weight: 400;
                color: #bbc3e4;
                line-height: 28px;
                margin-top: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
            .ymxk-responsibility-userinfo-detail {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin: 0px 31px 0px 54px;

              .ymxk-responsibility-userinfo {
                p {
                  font-size: 16px;
                  font-weight: 400;
                  color: #bbc3e4;
                  line-height: 28px;
                }
              }

              .ymxk-responsibility-img-right-more {
                display: flex;
                align-items: center;
                float: right;
                cursor: pointer;
                img {
                  width: 44px;
                  height: 45px;
                  margin-right: 10px;
                }
                .ymxk-responsibility-img-learn-more {
                  font-size: 14px;
                  font-weight: 400;
                  color: #bbc3e4;
                  line-height: 26px;
                }
              }
            }
          }
          .ymxk-responsibility-img-left-bottom {
            flex: 1;
            background: url("~@/assets/img/ymxk-responsibility-left-bottom.png")
              no-repeat;
            background-size: 100% 100%;
            z-index: 11;
            margin-right: -46px;
            text-align: left;
            .ymxk-responsibility-right-content {
              margin: 41px 55px 16px 30px;

              .ymxk-responsibility-right-consumer {
                font-size: 24px;
                color: #ffffff;
                line-height: 28px;
              }
              .ymxk-responsibility-right-detail {
                font-size: 16px;
                font-weight: 400;
                color: #bbc3e4;
                line-height: 28px;
                margin-top: 12px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }

            .ymxk-responsibility-userinfo-detail {
              display: flex;
              justify-content: space-between;
              align-items: flex-end;
              margin: 0 55px 0 30px;

              .ymxk-responsibility-userinfo {
                p {
                  font-size: 16px;
                  font-weight: 400;
                  color: #bbc3e4;
                  line-height: 28px;
                }
              }

              .ymxk-responsibility-img-right-more {
                display: flex;
                align-items: center;
                float: right;
                cursor: pointer;
                img {
                  width: 44px;
                  height: 45px;
                  margin-right: 10px;
                }
                .ymxk-responsibility-img-learn-more {
                  font-size: 14px;
                  font-weight: 400;
                  color: #bbc3e4;
                  line-height: 26px;
                }
              }
            }
          }
        }
        .ymxk-responsibility-img-list:last-child {
          margin-top: -1px;
        }
      }
    }
  }
}
</style>
