<template>
  <div class="number-scrolling">
    <div
      :class="['nums']"
      v-for="(item, index) in list"
      :key="index"
      :style="{ transform: start && `translateY(-${(item.length - 1) * 42}px)` }"
    >
      <div class="item" :key="inx" v-for="(itx, inx) in item">{{ itx }}</div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, defineProps, reactive } from 'vue';

const { figure, start } = defineProps({
  figure: {
    type: String,
    default: '0'
  },
  start: {
    type: Boolean,
    default: false
  }
});

const list = reactive([]);

onMounted(() => {
  const unit = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

  figure.split('').forEach((item, index) => {
    let nums = [];

    for (let i = 0; i < index; i++) {
      nums = [...nums, ...unit];
    }

    for (let i = 0; i <= item; i++) {
      nums.push(i);
    }

    list.push(nums);
  });
});
</script>
<style lang="scss">
.number-scrolling {
  display: flex;
  overflow: hidden;
  position: relative;
  height: 42px;
}

.nums {
  flex: 1;
  transition: all 2s ease-out;
  box-sizing: border-box;
  height: 42px;
}

.nums .item {
  font-size: 42px;
  color: #23a0ff;
  height: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}
</style>