export const getUrlParam = function(name, url) {
  var search = url || document.location.search;
  var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)'); //构造一个含有目标参数的正则表达式对象
  var r = search.substr(1).match(reg); //匹配目标参数

  // eslint-disable-next-line no-eq-null
  if (r != null) {
    return unescape(r[2]);
  }
  return null;
  //返回参数值
};