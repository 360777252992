<template>
  <div class="ymxk-back-top-container">
    <ul class="ymxk-back-top-list">
      <li
        @mouseenter="service(true)"
        @mouseleave="service(false)"
      >
        <a
          href="https://smartxmaweb.gomefinance.com.cn/vHelp?token=j6ni22uYVNb2&showChat=true&layout=true"
          target="_blank"
          rel="noopener noreferrer"
        ></a>
      </li>
      <li @click="handleBackToTop"  @mouseenter="backTop(true)"
        @mouseleave="backTop(false)"></li>
    </ul>
    <div class="service" v-if="isService">
      <img src="@/assets/img/kefu.png" />
      <p>在线客服</p>
    </div>
    <div class="top" v-if="isTop">
      <img src="@/assets/img/top.png" />
      <p>置顶</p>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue';
//在线客服
let isService = ref(false);

const service = (bool) => {
  isService.value = bool;
};
//置顶

let isTop = ref(false);

const backTop = (bool) => {
  isTop.value = bool;
};
// 滚动条滚动到顶部
const handleBackToTop = () => {
  //获取滚动条高度
  const height = document.documentElement.scrollTop || document.body.scrollTop;

  if (height > 20) {
    //自定义高度
    //将内容滚动到指定坐标
    window.scrollTo(0, 0);
  }
};

</script>

<style lang="scss" scoped>
.ymxk-back-top-container {
  width: 80px;
  position: fixed;
  bottom: 30px;
  right: 8px;
  z-index: 999999;
  .ymxk-back-top-list {
    li {
      width: 80px;
      height: 80px;
      cursor: pointer;
      a {
        display: block;
        width: 100%;
        height: 100%;
      }
      &:nth-child(1) {
        background: url('~@/assets/img/customer-service-logo.png') center center
          no-repeat;
        background-size: 100% 100%;
        &:hover {
          background: url('~@/assets/img/customer-service-logo-actived.png')
            center center no-repeat;
        }
      }
      &:nth-child(2) {
        background: url('~@/assets/img/back-top-logo.png') center center
          no-repeat;
        background-size: 100% 100%;
        &:hover {
          background: url('~@/assets/img/back-top-logo-actived.png') center
            center no-repeat;
        }
      }
    }
  }
  .service {
    width: 76px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 70px;
    top: 25px;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
      line-height: 17px;
      padding: 6px 10px;
    }
  }
  .top {
    width: 76px;
    height: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 70px;
    top: 125px;
    img {
      width: 100%;
      height: 100%;
    }
    p {
      position: absolute;
      font-size: 14px;
      font-weight: normal;
      color: #ffffff;
      line-height: 17px;
      padding: 6px 16px;
    }
  }
}
</style>
