import http from './api.js';
//首页轮播
export const banners = params => {
  return http.get('/api/v5/website/index/banners', { params });
};
//置顶的数据
export const getTopByType = params => {
  return http.get('/api/v5/website/news/getTopByType', { params });
};
//列表
export const getNews = params => {
  return http.get('/api/v5/website/news', { params });
};
//详情
export const detail = params => {
  return http.get('/api/v5/website/news/detail', { params });
};