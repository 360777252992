import { createRouter, createWebHistory } from 'vue-router';
import HomeView from '../views/HomeView/index.vue';

// import Layout from '../layout/default.vue';

const routes = [
  //首页
  {
    path: '/',
    redirect: '/index'
  },
  //首页
  {
    path: '/index',
    name: 'home',
    component: HomeView
  },
  //新闻列表
  {
    path: '/news',
    name: 'news',
    component: () => import(/* webpackChunkName: "news" */ '../views/NewsInformation/index.vue')
  },
  //新闻详情
  {
    path: '/newsDetail',
    name: 'newsDetail',
    component: () => import(/* webpackChunkName: "newsDetail" */ '../views/NewsInformation/newsDetail.vue')
  },
  //关于我们
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  //金融消费者保护
  {
    path: '/consumer',
    name: 'consumer',
    component: () => import(/* webpackChunkName: "consumer" */ '../views/ConsumerProtection/index.vue')
  },
  //金融消费者保护
  {
    path: '/consumerDetail',
    name: 'consumerDetail',
    component: () => import(/* webpackChunkName: "consumerDetail" */ '../views/ConsumerProtection/consumerDetail.vue')
  },
  //助力小微
  {
    path: '/assistance',
    name: 'assistance',
    component: () => import(/* webpackChunkName: "assistance" */ '../views/Assistance/index.vue')
  },
  //客户故事详情
  {
    path: '/assistanceDetail',
    name: 'assistanceDetail',
    component: () => import(/* webpackChunkName: "assistanceDetail" */ '../views/Assistance/assistanceDetail.vue')
  },
  //数字支付科技
  {
    path: '/numberPayment',
    name: 'numberPayment',
    component: () => import(/* webpackChunkName: "numberPayment" */ '../views/NumberPayment/index.vue')
  },
  //数字产融科技
  {
    path: '/numberFinance',
    name: 'numberFinance',
    component: () => import(/* webpackChunkName: "numberFinance" */ '../views/NumberFinance/index.vue')
  },
  //金融科技服务
  {
    path: '/financialTechnology',
    name: 'financialTechnology',
    component: () => import(/* webpackChunkName: "financialTechnology" */ '../views/FinancialTechnology/index.vue')
  },
  //数字场景科技
  {
    path: '/scene',
    name: 'scene',
    component: () => import(/* webpackChunkName: "scene" */ '../views/Scene/index.vue')
  }
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

export default router;
