<template>
  <div class="ymxk-layout-default-container">
      <Navigator :navId="$attrs.navId"></Navigator>
      <slot></slot>
      <Footer v-if="!honner"></Footer>
  </div>
</template>

<script setup>
import Navigator from '@/components/navigator.vue';
import Footer from '@/components/footer.vue';
const honner=localStorage.getItem('honner');

</script>

<style lang="scss">

</style>
