export const isInViewPortOfOne = (el) => {
  const dom = document.querySelector(el);
  // viewPortHeight 兼容所有浏览器写法
  // const viewPortHeight =
  //   window.innerHeight ||
  //   document.documentElement.clientHeight ||
  //   document.body.clientHeight;
  // const offsetTop = dom.offsetTop;
  // const scrollTop = document.documentElement.scrollTop;
  // const top = offsetTop - scrollTop;

  // return top <= viewPortHeight - 20;
  var top = dom.getBoundingClientRect().top; // 元素顶端到可见区域顶端的距离
  var clientHeight = document.documentElement.clientHeight; // 浏览器可见区域高度

  if (top < clientHeight ) {
    return true;
  }
};
