<template>
  <transition name="fade" enter-active-class="animated animate__slideInDown" leave-active-class="animated animate__slideOutUp">
    <div
      v-show="isShowYmxkNav"
      :class="{
        'ymxk-navigator-container': true,
        'ymxk-nav-white': isScrollOutdoNavHeight
      }"
    >
      <div class="ymxk-navigator-box">
        <img v-if="isScrollOutdoNavHeight" src="~@/assets/img/ymtop-logo.png" alt="logo" class="ymxk-navigator-logo">
        <img v-else src="~@/assets/img/ymxk-nav-logo.png" alt="logo" class="ymxk-navigator-logo">
        <ul class="ymxk-navigator-first-list">
          <li
            v-for="(firstNavItem, firstNavIndex) in navigatorList"
            :key="firstNavItem.id"
            class="ymxk-nav-first-item"
            @mouseenter="hanadleNavMouseEnter(firstNavItem, firstNavIndex)"
            @mouseleave="hanadleNavMouseLeave(firstNavItem, firstNavIndex)"
            :style="`width:${firstNavItem.width}`"
          >
            <p
              :class="{ 'ymxk-nav-first-item-text': true, activted: firstNavItem.id === navId }"
              v-if="firstNavItem.hasOwnProperty('secondaryList')"
            >
              {{ firstNavItem.text }}
            </p>
            <router-link v-else :class="{ 'ymxk-nav-first-item-text': true, activted: firstNavItem.id === navId }" :to="firstNavItem.path">
              {{ firstNavItem.text }}
            </router-link>
            <ul
              v-if="firstNavItem.isShowSelect"
              :class="{
                'ymxk-navigator-second-list': true,
                'ymxk-navigator-second-list-hign': firstNavItem.id === navId
              }"
            >
              <li class="ymxk-navigator-second-list-item" v-for="(secondNavItem, index) in firstNavItem.secondaryList" :key="index">
                <router-link
                  :class="{ 'ymxk-navigator-second-list-item-text': true, activted: secondNavItem.path === fullPath }"
                  :to="secondNavItem.path"
                >
                  {{ secondNavItem.text }}
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, reactive, computed, defineProps, onMounted, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();

const fullPath = ref(router.currentRoute.value.fullPath);

router.afterEach(({ hash }) => {
  const dom = document.querySelector(hash);

  fullPath.value = router.currentRoute.value.fullPath;
  if (hash && dom) {
    dom.scrollIntoView();
  }
});

// props
const { navId } = defineProps({
  navId: Number
});

const navigatorList = reactive([
  { id: 1, text: '首页', type: 'blank', path: '/index', isShowSelect: false, width: '92px' },
  {
    id: 2,
    text: '关于我们',
    type: 'blank',
    isShowSelect: false,
    path: '/about',
    secondaryList: [
      { text: '企业简介', type: 'self', path: '/about?anchor=1' },
      { text: '发展历程', type: 'self', path: '/about?anchor=2' },
      { text: '企业荣誉', type: 'self', path: '/about?anchor=3' },
      { text: '联系我们', type: 'self', path: '/about?anchor=4' }
    ],
    width: '124px'
  },
  {
    id: 3,
    text: '产品与服务',
    isShowSelect: false,
    path: '',
    secondaryList: [
      { text: '数字场景科技', type: 'self', path: '/scene' },
      { text: '数字产融科技', type: 'self', path: '/numberFinance' },
      { text: '数字支付科技', type: 'self', path: '/numberPayment' },
      { text: '金融科技服务', type: 'self', path: '/financialTechnology' }
    ],
    width: '140px'
  },
  {
    id: 4,
    text: '企业社会责任',
    isShowSelect: false,
    path: '',
    secondaryList: [
      { text: '消费者权益保护', type: 'self', path: '/consumer' },
      { text: '助力小微', type: 'self', path: '/assistance' }
    ],
    width: '156px'
  },
  { id: 5, text: '新闻动态', isShowSelect: false, path: '/news', width: '124px' }
]);

let timer = null;

let leavePrevNavId = null;
// 鼠标进入事件
const hanadleNavMouseEnter = (navItem) => {
  if ('secondaryList' in navItem) {
    if (leavePrevNavId === navItem.id) {
      clearTimeout(timer);
    }
    navItem.isShowSelect = true;
  }
};
// 鼠标离开事件
const hanadleNavMouseLeave = (navItem) => {
  if ('secondaryList' in navItem) {
    leavePrevNavId = navItem.id;
    timer = setTimeout(() => {
      navItem.isShowSelect = false;
    }, 50);
  }
};

// 下滑隐藏上滑出现
let isShowYmxkNav = ref(true);

let scrollTop = ref(0);

let isScrollOutdoNavHeight = computed(() => scrollTop.value > 80);
const changeShowYmxkNav = (bool) => {
  isShowYmxkNav.value = bool;
};

let oldScrollTop = 0;
const handlePageScroll = () => {
  // 滚动条距文档顶部的距离
  // scrollTop.value = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
  scrollTop.value = Math.max(window.pageYOffset || document.documentElement-scrollTop || document.body.scrollTop,0);
  // 滚动条滚动的距离
  let scrollStep = scrollTop.value - oldScrollTop;

  // console.log('header 滚动距离 ', scrollTop.value);
  // 更新——滚动前，滚动条距文档顶部的距离
  oldScrollTop = scrollTop.value;

  //变量windowHeight是可视区的高度
  let windowHeight = document.documentElement.clientHeight || document.body.clientHeight;
  //变量scrollHeight是滚动条的总高度

  let scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;

  //滚动条到底部的条件
  if (scrollTop.value + windowHeight === scrollHeight) {
    //你想做的事情
    // console.log('header  你已经到底部了');
  }
  if (scrollStep <= 0) {
    changeShowYmxkNav(true);
  } else {
    changeShowYmxkNav(false);
    // console.log(isScrollOutdoNavHeight.value);
  }
  // 判断是否到了最顶部
  if (scrollTop.value <= 0) {
    // console.log('header 到了最顶部');
  }
};

// 挂载和销毁scroll方法
onMounted(() => {
  window.addEventListener('scroll', handlePageScroll);
});
onBeforeUnmount(() => {
  window.removeEventListener('scroll', handlePageScroll);
});
</script>
<style lang="scss" scoped>
.ymxk-navigator-container {
  width: 100%;
  height: 100px;
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: url('~@/assets/img/ymxk-nav-bg.png') center center no-repeat;
  background-size: cover;
  &.ymxk-nav-white {
    background: #fff !important;
    .ymxk-nav-first-item-text {
      color: #000 !important;
      &.activted {
        border-bottom: 2px solid #23a0ff !important;
      }
    }
  }
  .ymxk-navigator-box {
    width: 1200px;
    height: 100px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .ymxk-navigator-logo {
      width: 213px;
    }
    .ymxk-navigator-first-list {
      display: flex;
      justify-content: space-between;
      margin-top: 10px;
      &:nth-child(2) {
        .ymxk-navigator-second-list {
          left: 10px;
        }
      }
      .ymxk-nav-first-item {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;

        .ymxk-nav-first-item-text {
          font-size: 16px;
          font-weight: normal;
          color: #ffffff;
          line-height: 18px;
          font-weight: 300;
          cursor: pointer;
          opacity: 0.7;
          border-radius: 1px;
          &.activted {
            opacity: 1;
            padding-bottom: 10px;
            border-bottom: 2px solid #fff;
          }
          &:hover {
            font-weight: 900;
            font-size: 17px;
            opacity: 0.8;
          }
        }
        .ymxk-navigator-second-list {
          position: absolute;
          top: 31px;
          width: 120px;
          padding: 6px 0;
          display: flex;
          flex-direction: column;
          background-color: #fff;
          box-shadow: 0px 2px 20px 0px rgba(0, 5, 79, 0.2);
          &.ymxk-navigator-second-list-hign {
            top: 45px;
          }
          &::before {
            position: absolute;
            top: -8px;
            left: 54px;
            content: '';
            border-bottom: 8px solid #fff;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
          }
          .ymxk-navigator-second-list-item {
            margin: 11px 0;
            width: 100%;
            text-align: center;
            .ymxk-navigator-second-list-item-text {
              font-size: 14px;
              font-weight: normal;
              color: #000000;
              line-height: 14px;
              text-shadow: 0px 2px 20px rgba(0, 5, 79, 0.2);
              &:hover {
                color: #23a0ff;
              }
              &.activted {
                color: #23a0ff;
              }
            }
          }
        }
      }
    }
  }
}
</style>
