import axios from 'axios';
import { getUrlParam } from './utils.js';

/* 添加在页面中切换接口环境 start */
const NODE_ENV =
  process.env.NODE_ENV !== 'production' ?
    process.env.NODE_ENV !== 'test' ?
      'dev' :
      'test' :
    'prod';

const baseURL = {
  dev: '/dev',
  test: window.location.origin || 'https://test.gomefinance.com.cn',
  prod: window.location.origin || 'https://www.gomefinance.com.cn'
}[NODE_ENV];

// 创建新的axios实例
const httpRequest = axios.create({
  baseURL, // 默认路径
  timeout: 60000, // 默认超时时间
  headers: { // 公共请求头参数
    plat: 'pc',
    channel: getUrlParam('refm') || sessionStorage.getItem('refm_bd') || '',
    channelUrl: sessionStorage.getItem('channelUrl') || '',
    inviteCode:
      getUrlParam('gatewayInviteCode') ||
      sessionStorage.getItem('gatewayInviteCode') ||
      '',
    landingUrl: sessionStorage.getItem('landingUrl')
  }
});

// 请求拦截器
httpRequest.interceptors.request.use(
  config => {
    config.url = `${config.url}${config.url.includes('?') ? '&t=' + Date.parse(new Date()) :'?t=' + Date.parse(new Date())}`;
    return config;
  },
  error => {
    Promise.reject(error);
  }
);

// 响应拦截器
httpRequest.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    return Promise.reject(error);
  }
);

export default httpRequest;