<template>
  <div class="ymxk-news-container">
    <div class="bg-left"></div>
    <div class="bg-right"></div>
    <div class="ymxk-news-box">
      <div class="ymxk-news-content">
        <div class="ymxk-news-title">
          <span class="ymxk-news-title-left"></span>
          <span class="ymxk-news-title-middle">新闻动态</span>
          <span class="ymxk-news-title-right"></span>
        </div>
        <div class="ymxk-news-text">
          <div class="ymxk-news-left" v-for="item in news.videoPhoto">
            <div v-if="item.videoUrl" class="ymxk-news-distinguish">
              <div class="video">
                <vue3VideoPlay
                  :src="item.videoUrl"
                  :poster="item.imageUrl"
                  v-if="item.imageUrl"
                >
                </vue3VideoPlay>
                <vue3VideoPlay
                  :src="item.videoUrl"
                  v-else
                >
                </vue3VideoPlay>
              </div>
              <div @click="recommend(item)">
                <p class="ymxk-news-topic">{{ item.title }}</p>
                <p class="ymxk-news-introduce">{{ item.abstractContent }}</p>
                <p class="ymxk-news-time">
                  {{ item.eventTime.replace(/-/g,'.') }}
                </p>
              </div>
            </div>
            <div v-else class="ymxk-news-distinguish" @click="recommend(item)">
              <!-- 图片 -->
              <div class="sd-scale">
                <img :src="item.imageUrl" alt="" v-if="item.imageUrl" />
                <div class="noimg" v-else >
                </div>
              </div>
              <p class="ymxk-news-topic">{{ item.title }}</p>
              <p class="ymxk-news-introduce">{{ item.abstractContent }}</p>
              <p class="ymxk-news-time">
                {{ item.eventTime.replace(/-/g,'.')}}
              </p>
            </div>
          </div>
          <div class="ymxk-news-right-content">
            <div class="ymxk-news-right" v-for="item in news.total.slice(0, 3)">
              <div class="ymxk-news-list" @click="ordinary(item)">
                <div class="ymxk-news-date">
                  <p class="ymxk-news-day">
                    {{ item.eventTime.replace(/-/g,'.').split('.')[2] }}
                  </p>
                  <p class="ymxk-news-year">
                    {{ item.eventTime.replace(/-/g,'.').slice(0, 7) }}
                  </p>
                </div>
                <div class="ymxk-news-right-list-detail">
                  <p class="ymxk-news-topic">{{ item.title }}</p>
                  <p class="ymxk-news-introduce">{{ item.abstractContent }}</p>
                </div>
              </div>
            </div>
            <div class="yxmk-news-more">
              <span class="yxmk-news-more-click" @click="moreNews"
                >更多新闻</span
              >
              <img src="~@/assets/img/ymxk-more.png" alt="" @click="moreNews" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { reactive, onMounted } from 'vue';
import { getTopByType,getNews } from '../assets/js/http';
const news = reactive({
  total: [
    // {
    //   title:
    //     '国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血 万券齐发”岁末大促活动火爆来袭国美易卡“全民回血 万券齐发”岁末大促活动火爆来袭',
    //   abstractContent:
    //     '国美信科启动“遏万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血制反催收黑产专项行动”主题宣传月活动国美信科启动“遏制反催收黑产专项行动”主题宣传月活动国美信科启动“遏制反催收黑产专项行动”',
    //   eventTime: '2022-12-20',
    //   imageUrl: ''
    // },
    // {
    //   title:
    //     '国美易卡“全民回血 万券齐发”岁末大促活动火爆来袭国美易卡“全民回血 万券齐发”岁末大促活动火爆来袭',
    //   abstractContent:
    //     '国美信科启动“遏制反催收黑产专项行动”主题宣传月活动国美信科启动“遏制反催收黑产专项行动”主题宣传月活动国美信科启动“遏制反催收黑产专项行动”',
    //   eventTime: '2022-12-20',
    //   imageUrl: ''
    // },
    // {
    //   title:
    //     '国美易卡“全民回血 万券齐发”岁末大促活动火爆来袭国美万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血易卡“全民回血 万券齐发”岁末大促活动火爆来袭',
    //   imageUrl: '',
    //   abstractContent:
    //     '国美信科启动“遏制反催收黑产专项行动”主题宣传月活动国美信科启动“遏制反催收黑产专项行动”万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血主题宣传月活动国美信科启动“遏制反催收黑产专项行动”',
    //   eventTime: '2022-12-20'
    // },
    // {
    //   title:
    //     '国美易卡“全民回血 万券齐发”岁末大促活动火爆来袭国美万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血易卡“全民回血 万券齐发”岁末大促活动火爆来袭',
    //   imageUrl: '',
    //   abstractContent:
    //     '国美信科启动“遏制反催收黑产专项行动”主题宣传月活动国美信科启动“遏制反催收黑产专项行动”万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血万券齐发”岁末大促活动火爆来袭国美易卡“全民回血主题宣传月活动国美信科启动“遏制反催收黑产专项行动”',
    //   eventTime: '2022-12-20'
    // }
  ],
  //置顶视频/图片
  recommendList: [
  // {
  //   abstractContent:
  //     '国美信科作为金融科技代表企业，一直以切实保护金融消费者合法权益为目的，其“国美信科金融反诈宣传”营销案例从517个营销案例中脱颖而出，荣膺“B2C年度内容营销奖”',
  //   eventTime: '2022-12-20',
  //   imageUrl:
  //     'https://static.gomefinance.com.cn/cms/website/9c8313a36aa6390a23a00982746007d-1676360297315.png',
  //   title: '国美信科四度荣膺金匠奖“B2C年度内容营销奖”',
  //   videoUrl: 'https://s2.pailifan.com/mengniu/20220942/chun_zhen_30s.mp4'
  // }
  ],
  //推荐视频
  vedio: [
    {
      width: '517px', //播放器高度
      height: '299px', //播放器高度
      muted: true, //静音
      webFullScreen: false,
      speedRate: ['0.75', '1.0', '1.25', '1.5', '2.0'], //播放倍速
      autoPlay: false, //自动播放
      loop: false, //循环播放
      mirror: false, //镜像画面
      ligthOff: false, //关灯模式
      volume: 0.3, //默认音量大小
      control: true, //是否显示控制
      controlBtns: [
        'audioTrack',
        'quality',
        'speedRate',
        'volume',
        'setting',
        'pip',
        'pageFullScreen',
        'fullScreen'
      ] //显示所有按钮,
    }
  ],
  blvideo: [],
  videoPhoto: [] //组装视频/图片数据
});

onMounted(() => {
  //置顶新闻动态
  getTopByType({ columnType: 1 }).then((res) => {
    if (res && res.data.code === 200) {
      news.recommendList = [res.data.data];
      //置顶的视频处理
      news.recommendList.forEach((item) => {
        if (item.videoUrl) {
          news.blvideo.push(item);
          news.videoPhoto = [{ ...news.vedio[0], ...news.blvideo[0] }];
        } else {
          news.videoPhoto.push(item);
        }
      });
    } else {
      console.log(res.data.msg);
    }
  });
  //普通的新闻动态
  getNews({ columnType: 1,top:0 }).then((res) => {
    if (res && res.data.code === 200) {
      news.total=res.data.data.news;
    } else {
      console.log(res.data.msg);
    }
  });
});
const router = useRouter();
//左边推荐新闻
const recommend = (item) => {
  router.push({
    path: '/newsDetail',
    query: { id:item.id }
  });
};
//右边普通的新闻
const ordinary = (item) => {
  router.push({
    path: '/newsDetail',
    query: {id:item.id }
  });
};
//更多新闻
const moreNews = () => {
  router.push({
    path: '/news'
  });
};

</script>
<style lang="scss" scoped>
.ymxk-news-container {
  background: linear-gradient(
    136deg,
    rgba(131, 203, 255, 0.1) 0%,
    rgba(35, 163, 255, 0.1) 100%
  );

  position: relative;
  .bg-left {
    background-image: url('../assets/img/bg/news-left.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 740px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .bg-right {
    background-image: url('../assets/img/bg/news-right.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 740px;
    position: absolute;
    right: 0;
    top: 0;
  }
  .ymxk-news-box {
    position: relative;
    width: 100%;
    height: 100%;
    .ymxk-news-content {
      width: 1200px;
      margin: 0 auto;
      text-align: center;
      padding: 59px 0 53px;
      .ymxk-news-title {
        margin-bottom: 30px;
        .ymxk-news-title-left {
          width: 8px;
          height: 8px;
          background: #23a1ff;
          opacity: 0.3;
          display: inline-block;
          vertical-align: top;
        }
        .ymxk-news-title-middle {
          width: 120px;
          height: 30px;
          font-size: 30px;
          font-weight: bold !important;
          color: #000000;
          line-height: 30px;
          margin: 0 10px;
        }
        .ymxk-news-title-right {
          width: 8px;
          height: 8px;
          background: #23a1ff;
          opacity: 0.3;
          display: inline-block;
        }
      }
      .ymxk-news-text {
        display: flex;
        .ymxk-news-left {
          .ymxk-news-distinguish {
            width: 517px;
            font-size: 0;
            text-align: left;
            cursor: pointer;
            margin-right: 83px;
            .sd-scale {
              width: 517px;
              height: 299px;
              overflow: hidden;
              box-shadow: 0px 8px 20px 0px rgba(0, 47, 83, 0.3);
              border: 3px solid #ffffff;
              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all 0.5s;
              }
              img:hover {
                transform: scale(1.1);
              }
              .noimg{
                width: 100%;
                height: 100%;
                background-color: #f4faff;
              }
            }
            .video {
              width: 517px;
              height: 299px;
              /deep/ .d-player-wrap[data-v-01791e9e] {
                width: 100%;
                height: 100%;
              }
            }
            .ymxk-news-topic {
              width: 521px;
              font-size: 18px;
              color: #000000;
              line-height: 28px;
              font-weight:bold !important;
              margin: 14px 0 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .ymxk-news-introduce {
              width: 518px;
              font-size: 14px;
              color: #64696C;
              line-height: 30px;
              margin-bottom: 12px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .ymxk-news-time {
              font-size: 14px;
              color: #64696C;
              line-height: 14px;
            }
          }
        }
        .ymxk-news-right-content {
          display: flex;
          flex-direction: column;
          .ymxk-news-right {
            width: 600px;
            cursor: pointer;
            margin-bottom: 41px;
            padding: 15px 0 12px 14px;
            .ymxk-news-list {
              display: flex;
              .ymxk-news-date {
                .ymxk-news-day {
                  height: 49px;
                  font-size: 42px;
                  color: #23a0ff;
                  line-height: 49px;
                }
                .ymxk-news-year {
                  height: 14px;
                  font-size: 14px;
                  color: #64696C;
                  line-height: 14px;
                  margin-top: 2px;
                }
              }
              .ymxk-news-right-list-detail {
                text-align: left;
                width: 500px;
                margin: 0 0 0 30px;

                .ymxk-news-topic {
                  font-size: 18px;
                  color: #000000;
                  line-height: 28px;
                  font-weight: bold !important;
                  margin: 0 0 8px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
                .ymxk-news-introduce {
                  font-size: 14px;
                  color: #64696C;
                  line-height: 30px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                }
              }
            }
            &:hover {
              background: #f4faff;
              box-shadow: 0px 0px 20px 0px rgba(0, 47, 83, 0.15);
            }
          }
          .ymxk-news-right:hover
            .ymxk-news-list
            .ymxk-news-right-list-detail
            .ymxk-news-topic {
            color: #23a0ff;
          }
          .yxmk-news-more {
            display: flex;
            align-items: center;
            margin-left: 100px;
            .yxmk-news-more-click {
              font-size: 16px;
              color: #23a0ff;
              line-height: 16px;
              margin-right: 5px;
              cursor: pointer;
            }
            img {
              width: 8px;
              height: 8px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.d-player-video-main {
  display: block !important;
}
</style>
