<template>
  <div class="ymxk-product-server-container">
    <div class="img"></div>
    <div class="ymxk-product-server-box">
      <div class="ymxk-product-server-title">
        <span class="ymxk-product-server-title-left"></span>
        <span class="ymxk-product-server-title-middle">产品与服务</span>
        <span class="ymxk-product-server-title-right"></span>
      </div>
      <div class="ymxk-product-server-describe">
        <p>
          盈美信科依托多年以来在人工智能、数字决策、智能风控、云计算等方面积累的丰厚实践经验及金融科技能力，重点布局数字场景科技、数字产融科技以及数字支付科技等三个发展方向，为更多元化客户提供更为丰富的金融科技服务产品，助力产业数字化转型发展。
        </p>
      </div>
      <div class="ymxk-product-server-list">
        <div class="ymxk-product-server-img-box">
          <img
            :src="productActivedItem.imgSrc"
            :alt="productActivedItem.name"
          />
        </div>
        <div class="ymxk-product-server-introduce-box">
          <ul class="ymxk-product-server-introduce-title-list">
            <li
              v-for="item in productServeList"
              :key="item.id"
              :class="{ actived: item.id === productActivedId }"
              @mouseenter="handleProductMouseEnter(item)"
              @mouseleave="handleProductMouseLeave"
            >
              <div class="sx" >
                <div class="sg" v-if="item.id === productActivedId">
                  <p class="ymxk-development-white"></p>
                  <p class="ymxk-development-blue"></p>
                </div>
                <p>{{ item.name }}</p>
              </div>
            </li>
          </ul>
          <div class="ymxk-product-server-introduce-content-list">
            <p :class="{ scrollChange: scrollFade }">
              {{ productActivedItem.desc }}
            </p>
            <a :class="{ scrollChange: scrollFade }" @click="goDetails(item)">
              <img src="@/assets/img/ymxk-learn-more.png" alt="more-logo" />
              <span>了解更多</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, computed, onMounted, onUnmounted } from 'vue';
import { useRouter } from 'vue-router';

const productServeList = [
  {
    id: 1,
    name: '数字场景科技',
    imgSrc: require('../assets/img/index-cjkj.png'),
    desc: '聚焦普惠金融，依托数字科技，为新市民及小微企业用户提供方便、快捷、安心的创新金融科技服务。'
  },
  {
    id: 2,
    name: '数字产融科技',
    imgSrc: require('../assets/img/index-crkj.png'),
    desc: '以大数据风控为核心，依托场景化交易，通过主动授信，为广大企业提供应收账款、存货质押、票据质押等不同类型的融资服务，解决企业在发展过程中的资金需求，大幅减少融资成本。致力于打造企业融资、资信评估、账款催收于一体的专业性融资服务平台。'
  },
  {
    id: 3,
    name: '数字支付科技',
    imgSrc: require('../assets/img/index-zfkj.png'),
    desc: '专注互联网支付、跨境支付业务，致力于为企业提供专业的定制化支付解决方案，满足客户多场景下的专业支付清算需求。'
  }
];

const router = useRouter();
const goDetails = () => {
  console.log(productActivedId);
  if (productActivedId.value === 1) {
    router.push({ path: '/scene', query: {} });
  }

  if (productActivedId.value === 2) {
    router.push({ path: '/numberFinance', query: {} });
  }

  if (productActivedId.value === 3) {
    router.push({ path: '/numberPayment', query: {} });
  }
};

let productActivedId = ref(1);

const productActivedItem = computed(() =>
  productServeList.find((item) => item.id === productActivedId.value)
);

let timer = null;

let scrollFade = ref(false);
// const handleProductCountDown = () => {
//   clearInterval(timer);
//   timer = setInterval(() => {
//     if (productActivedId.value === productServeList.length) {
//       productActivedId.value = 0;
//     }
//     productActivedId.value++;
//   }, 5000);
// };

const handleProductMouseEnter = ({ id }) => {
  clearInterval(timer);
  productActivedId.value = id;
  setTimeout(() => {
    scrollFade.value = true;
  }, 0);
};
const handleProductMouseLeave = () => {
  //handleProductCountDown();
  scrollFade.value = false;
};

onMounted(() => {
  //handleProductCountDown();
});
onUnmounted(() => {
  clearInterval(timer);
});
</script>
<style lang="scss" scoped>
.ymxk-product-server-container {
  position: relative;
  padding: 60px 0;
  background: linear-gradient(
    136deg,
    rgba(131, 203, 255, 0.1) 0%,
    rgba(35, 163, 255, 0.1) 100%
  );
  .img {
    background-image: url('../assets/img/bg/prodect-bg.png');
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 1920px;
    height: 809px;
    position: absolute;
    left: 0;
    top: 0;
  }
  .ymxk-product-server-box {
    width: 1200px;
    margin: 0 auto;
    position: relative;
    .ymxk-product-server-title {
      margin-bottom: 10px;
      text-align: center;
      .ymxk-product-server-title-left {
        width: 8px;
        height: 8px;
        background: #23a1ff;
        opacity: 0.3;
        display: inline-block;
        vertical-align: top;
      }
      .ymxk-product-server-title-middle {
        width: 120px;
        height: 30px;
        font-size: 30px;
        font-weight: bold !important;
        color: #000000;
        line-height: 30px;
        margin: 0 10px;
      }
      .ymxk-product-server-title-right {
        width: 8px;
        height: 8px;
        background: #23a1ff;
        opacity: 0.3;
        display: inline-block;
      }
    }
    .ymxk-product-server-describe {
      width: 100%;
      box-sizing: border-box;
      padding: 0 50px;
      font-size: 14px;
      font-weight: normal;
      color: #4C4D4E;
      line-height: 28px;
      text-align: center;
      margin-top: 17px;
      margin-bottom: 44px;
    }
    .ymxk-product-server-list {
      position: relative;
      .ymxk-product-server-img-box {
        width: 1200px;
        height: 540px;
        overflow: hidden;
        img {
          width: 100%;
        }
      }
      .ymxk-product-server-introduce-box {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 1200px;
        height: 540px;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        .ymxk-product-server-introduce-title-list {
          width: 230px;
          height: 540px;
          li {
            width: 100%;
            height: 180px;
            background: rgba(73, 78, 82, 0.8);
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 24px;
            font-weight: normal;
            color: #ffffff;
            line-height: 28px;
            cursor: pointer;
            &.actived {
              background: none;
              text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.5);
            }

            .sx {
              display: flex;
              align-items: center;
              .sg {
                margin-right: 13px;
                .ymxk-development-white {
                  background: #ffffff;
                  width: 3px;

                  height: 12px;
                }
                .ymxk-development-blue {
                  background: #23a0ff;
                  width: 3px;

                  height: 12px;
                }
              }
            }
          }
        }
        .ymxk-product-server-introduce-content-list {
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 970px;
          // height: 180px;
          box-sizing: border-box;
          padding: 61px 60px 26px 120px;
          font-size: 16px;
          font-weight: 400;
          color: #ffffff;
          line-height: 31px;
          p {
            width: 453px;
            //font-family: lth;
          }
          a {
            display: flex;
            align-items: center;
            color: #fff;
            span {
              margin-left: 10px;
            }
            img {
              width: 44px;
            }
          }
          a:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.scrollChange {
  animation: scrollFisrt .3s ease-in-out;
}
@keyframes scrollFisrt {
  0% {
    transform: translateX(20);
    opacity: 0;
  }
  50% {
    transform: translateX(10px);
    opacity: 0;
  }
  100% {
    transform: translateX(0px);
    opacity: 1;
  }
}
</style>
